import React, { useEffect } from "react";

const TradingViewWidget: React.FC = () => {
  useEffect(() => {
    const scriptId = "tradingview-widget-script";

    // Vérifiez si le script existe déjà
    if (document.getElementById(scriptId)) {
      return;
    }

    const script = document.createElement("script");
    script.id = scriptId; // Ajoutez un ID unique pour identifier le script
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        { proName: "BITSTAMP:BTCUSD", title: "Bitcoin" },
        { proName: "BITSTAMP:ETHUSD", title: "Ethereum" },
        { description: "Cronos", proName: "COINBASE:CROUSD" },
        { description: "Solana", proName: "COINBASE:SOLUSD" },
        { description: "Doge", proName: "BINANCE:DOGEUSDT" },
      ],
      showSymbolLogo: true,
      isTransparent: false,
      displayMode: "adaptive",
      colorTheme: "dark",
      locale: "en",
    });

    const container = document.querySelector(".tradingview-widget-container__widget");
    container?.appendChild(script);
  }, []);

  return (
    <div className="tradingview-widget-container">
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default TradingViewWidget;