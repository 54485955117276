import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget() {
  const container = useRef();

  useEffect(() => {
    if (container.current && !container.current.querySelector("script")) {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-crypto-coins-heatmap.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "dataSource": "Crypto",
          "blockSize": "market_cap_calc",
          "blockColor": "change",
          "locale": "fr",
          "symbolUrl": "",
          "colorTheme": "dark",
          "hasTopBar": false,
          "isDataSetEnabled": false,
          "isZoomEnabled": true,
          "hasSymbolTooltip": true,
          "isMonoSize": false,
          "width": "100%",
          "height": "96%"
        }`;
      container.current.appendChild(script);
  
      console.log("Script ajouté :", script);
    }
  }, []);

  useEffect(() => {
    const adjustHeight = () => {
      if (container.current) {
        container.current.style.height = `${window.innerHeight}`; // Dynamically set the height
      }
    };

    // Adjust height on mount and window resize
    adjustHeight();
    window.addEventListener("resize", adjustHeight);

    return () => window.removeEventListener("resize", adjustHeight); // Cleanup on unmount
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
      }}
    >
      <div ref={container} style={{ width: "100%", height: "100%" }}></div>
    </div>
  );
}

export default memo(TradingViewWidget);