import React from 'react';
import './App.css';
import DrawChart from './Components/TradingViewWidget.tsx';
import Banner from './Components/Banner.tsx';
function App() {
  return (
    <div className="App">
      <Banner />
      <DrawChart />
    </div>
  );
}

export default App;